<template>
  <div
    v-if="!hasRegistered"
    id="RegisterForm"
    class="bg-white text-black p-6 rounded-[3.2em] flex flex-col items-center mb-4"
  >
    <div class="space-y-4 px-2 mt-2 mb-4 w-full">
      <span
        :class="(!errors.formError ? 'hidden' : 'error-box text-space-darker-red w-full mb-3 block')"
      >
        {{ errors.formError }}
      </span>
      <TextFormInput
        placeholder="johndoe"
        :wrapper-classname="'bg-space-dark-blue'"
        type="text"
        :value="formData.pseudo"
        field="pseudo"
        :errors="errors"
        :label="$t('registrationForm.username')"
        @input="onFieldChanged('pseudo', $event)"
      />
      <TextFormInput
        placeholder="john.doe@gmail.com"
        type="text"
        :wrapper-classname="'bg-space-dark-blue'"
        :value="formData.email"
        field="email"
        :errors="errors"
        :label="$t('registrationForm.email')"
        @input="onFieldChanged('email', $event)"
      />
      <TextFormInput
        :placeholder="$t('registrationForm.password')"
        type="password"
        :wrapper-classname="'bg-space-dark-blue'"
        :value="formData.password"
        field="password"
        :errors="errors"
        :label="$t('registrationForm.password')"
        @input="onFieldChanged('password', $event)"
      />
      <TextFormInput
        :placeholder="$t('registrationForm.confirmPassword')"
        type="password"
        :wrapper-classname="'bg-space-dark-blue'"
        :value="formData.confirmPassword"
        field="confirmPassword"
        :errors="errors"
        :label="$t('registrationForm.confirmPassword')"
        @input="onFieldChanged('confirmPassword', $event)"
      />
    </div>
    <p class="text-center text-xs">
      <span>{{ $t('registrationForm.terms.first') }}<br>{{ $t('registrationForm.terms.second') }}<span class="font-bold">{{ $t('registrationForm.terms.and') }}</span>{{ $t('registrationForm.terms.last') }}</span>
    </p>

    <PrimaryButton
      :bg-color="'bg-space-dark-blue'"
      :text="$t('registrationForm.submit')"
      @click="onRegister"
      :classes="'w-2/3 mt-8 text-white'"
    />
    <p class="text-xs mt-4 text-center">
      {{ $t('registrationForm.alreadyRegistered') }}
      <router-link
        :to="{name: 'login'}"
        class="font-bold no-underline cursor-pointer"
      >
        {{ $t('common.signIn') }}
      </router-link>
    </p>
  </div>
  <RegisterConfirm v-else />
</template>

<script>
import TextFormInput from "@/components/inputs/TextFormInput";
import UserService from "@/services/userService";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import FormService from "@/services/formService";
import RegisterConfirm from "@/components/register/RegisterConfirm";
import i18n from "@/i18n/i18n";
import {isNull} from "@/utils/isNull";

export default {
  name: "RegisterForm",
  components: {RegisterConfirm, PrimaryButton, TextFormInput},
  data() {
    return {
      hasRegistered: false,
      formData: {
        pseudo : "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      errors: {},
      id: null
    };
  },
  async mounted() {
    this.hasRegistered = !!this.$route.query.OAuthRegister;
    const user = this.$store.getters.getUser;
    this.formData.pseudo = !isNull(user.id) ? user.pseudo : '';
    await this.$recaptchaLoaded();
    this.$recaptchaInstance.showBadge();
  },
  destroyed() {
    this.$recaptchaInstance.hideBadge();
  },
  methods: {
    async onRegister() {
      const formErrors = {
        pseudo: FormService.checkNotEmpty(this.formData.pseudo),
        email: FormService.hasValidEmailFormat(this.formData.email),
        password: FormService.hasValidPassword(this.formData.password),
        confirmPassword: FormService.hasValidConfirmPassword(this.formData.password, this.formData.confirmPassword)
      };

      this.errors = {};
      if (FormService.hasErrors(formErrors)){
        this.errors = formErrors;
        return;
      }
      try {
        const token = await this.$recaptcha('register');
        await UserService.signUp(this.formData, token);
      } catch ({response}){
        if ((response?.status === 400 || response?.status === 422) && response?.data?.code) {
          this.errors = {
            formError: i18n.t(`registrationForm.errors.${response.data.code}`)
          };
        }
        return;
      }
      this.hasRegistered = true;
    },
    onFieldChanged(field, value){
      this.formData[field] = value;
      this.errors[field] = undefined;
    }
  }
};
</script>

<style scoped>
</style>
