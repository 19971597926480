<template>
  <div class="w-full rounded-[3.2em] bg-white py-8 px-12 text-space-dark-blue">
    <h1 class="text-4xl text-space-dark-blue font-bold font-raleway-extra-bold">
      {{ $t('registrationForm.confirm.title') }}
    </h1>
    <span>{{ $t('registrationForm.confirm.subtitle') }}&#128640;</span>
    <div class="relative mt-5">
      <p class="text-justify text-yellow-500">
        {{ $t('registrationForm.confirm.content') }}
      </p>
    </div>

    <span class="mt-8 block">{{ $t('registrationForm.confirm.textEnd') }}</span>
  </div>
</template>
<script>

export default {
  name: "RegisterConfirm"
};
</script>
