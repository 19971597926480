<template>
  <AuthentificationLayout>
    <template #background-image>
      <img
        alt="planet ice"
        :data-src="require('@/assets/images/planet-ice.svg')"
      >
    </template>
    <template #text-page>
      <p class="geminis text-7xl lg:text-8xl xl:text-9xl text-shadow text-right">
        {{ $t('common.welcome.first') }} {{ $t('common.welcome.second') }}!
      </p>
    </template>
    <template #form>
      <p class="geminis text-space-green text-4xl text-center pb-3">
        {{ $t('registrationForm.title') }}
      </p>
      <RegisterForm />
    </template>
  </AuthentificationLayout>
</template>

<script>
import RegisterForm from '../../components/register/RegisterForm.vue';
import AuthentificationLayout from "../../layouts/partials/authentification-layout/AuthentificationLayout";
export default {
  name : "Register",
  components: {AuthentificationLayout, RegisterForm }
};
</script>
